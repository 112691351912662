const theme = require('../components/tailwind.config')
const resolveConfig = require('tailwindcss/resolveConfig')

const { content, ...themeWithoutContent } = theme

module.exports = resolveConfig(themeWithoutContent, {
  content: [
    './src/**/*.{js,jsx,ts,tsx,css,ejs}',
    '../components/src/**/*.{js,jsx,ts,tsx,css}',
    '../data-browser/src/**/*.{js,jsx,ts,tsx,css}',
    '../dql-editor/src/**/*.{js,jsx,ts,tsx,css}',
    '../forge/src/**/*.{js,jsx,ts,tsx,css}',
    './public/index.html',
  ],
  plugins: [require('tailwind-scrollbar-hide')],
})
