import { MainLayout } from 'components/layout'
import React, { Suspense, useContext } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import {
  OrganizationAppIdResolver,
  UserAppIdResolver,
} from 'scenes/appManagement'
import { EmployeeGroupManagementRouter } from 'scenes/employeeGroupManagement'
import UserAppsRouter from 'scenes/userManagement/tabs/apps/UserAppsRouter'
import UserManagementLayout from 'scenes/userManagement/UserManagementLayout'

import AppContext from './context'
import { useFeatureFlags } from './utils/featureFlags'

const IS_PRODUCTION = process.env.NODE_ENV === 'production'

/* -------------------------------------------------------------------------- */
/*                            App-specific Routes                             */
/* -------------------------------------------------------------------------- */

const DataBrowserScene = React.lazy(() => import('scenes/dataBrowser'))
const DQLEditorScene = React.lazy(() => import('scenes/dqlEditor'))
const AppForgeEntry = React.lazy(() => import('scenes/forge'))

/* -------------------------------------------------------------------------- */
/*                            User Tenant Routes                              */
/* -------------------------------------------------------------------------- */

const CreateUserAppScene = React.lazy(
  () => import('scenes/userManagement/createUserApp'),
)
const FeedbackScene = React.lazy(() => import('scenes/feedback'))
const HomeScene = React.lazy(() => import('scenes/home'))
const InvitationScene = React.lazy(() => import('scenes/invitation'))
const NotificationsScene = React.lazy(
  () => import('scenes/userManagement/notifications'),
)
const UserOrganizationsScene = React.lazy(
  () =>
    import('scenes/userManagement/tabs/organizations/UserOrganizationsScene'),
)
const UserEmployeeGroupsScene = React.lazy(
  () =>
    import('scenes/userManagement/tabs/employeeGroups/UserEmployeeGroupsScene'),
)
const UserSettingsScene = React.lazy(
  () => import('scenes/userManagement/tabs/settings/UserSettingsScene'),
)

/* -------------------------------------------------------------------------- */
/*                        Organization Tenant Routes                          */
/* -------------------------------------------------------------------------- */

const CreateOrganizationScene = React.lazy(
  () => import('scenes/createOrganization'),
)
const CreateOrganizationAppScene = React.lazy(
  () => import('scenes/organizationManagement/CreateOrganizationAppScene'),
)
const OrganizationSettingsScene = React.lazy(
  () => import('scenes/organizationManagement/tabs/settings'),
)
const OrganizationActivity = React.lazy(
  () => import('scenes/organizationManagement/tabs/activity'),
)
const OrganizationApps = React.lazy(
  () => import('scenes/organizationManagement/tabs/apps'),
)
const OrganizationManagementScene = React.lazy(
  () => import('scenes/organizationManagement'),
)

/* -------------------------------------------------------------------------- */
/*                               Employee-only                                */
/* -------------------------------------------------------------------------- */

const CreateEmployeeGroupScene = React.lazy(
  () => import('scenes/createEmployeeGroup'),
)
const FindAppScene = React.lazy(() => import('scenes/findApp'))
const FindOrganizationScene = React.lazy(() => import('scenes/organizations'))
const FindEmployeeGroupScene = React.lazy(
  () => import('scenes/findEmployeeGroup'),
)
const UsersScene = React.lazy(() => import('scenes/users'))

const AppRouter = () => {
  const { isSuperAdmin } = useContext(AppContext)
  const { organizationActivity, dqlEditor, appForge } = useFeatureFlags()

  return (
    <MainLayout>
      <Suspense fallback={null}>
        <Routes>
          <Route>
            <Route
              path="/users"
              element={isSuperAdmin ? <UsersScene /> : <Navigate to="/" />}
            />
            <Route
              path="/find-app"
              element={isSuperAdmin ? <FindAppScene /> : <Navigate to="/" />}
            />
            <Route
              path="/find-organization"
              element={
                isSuperAdmin ? <FindOrganizationScene /> : <Navigate to="/" />
              }
            />
            {isSuperAdmin && (
              <Route
                path="/find-employee-group"
                element={<FindEmployeeGroupScene />}
              />
            )}
            <Route path="/feedback" element={<FeedbackScene />} />

            {/* TODO(forge): remove this production check */}
            {(!IS_PRODUCTION || !!appForge) && (
              <Route path="/forge/*" element={<AppForgeEntry />} />
            )}

            <Route path="/data-browser/:appId" element={<DataBrowserScene />} />
            {!!dqlEditor && (
              <Route path="/dql-editor/:appId" element={<DQLEditorScene />} />
            )}
            <Route
              path="/organizations/create"
              element={<CreateOrganizationScene />}
            />
            {isSuperAdmin && (
              <Route
                path="/employee-groups/new"
                element={<CreateEmployeeGroupScene />}
              />
            )}
            {isSuperAdmin && (
              <Route
                path="/employee-groups/:employeeGroupSlug/*"
                element={<EmployeeGroupManagementRouter />}
              />
            )}
            <Route
              path="/invitation/:invitationId"
              element={<InvitationScene />}
            />
            <Route
              path="/:organizationSlug"
              element={<OrganizationManagementScene />}
            >
              <Route path="new-app" element={<CreateOrganizationAppScene />} />
              <Route
                path="settings/*"
                element={<OrganizationSettingsScene />}
              />
              <Route path="apps" element={<OrganizationApps />} />
              {(organizationActivity || isSuperAdmin) && (
                <Route path="activity" element={<OrganizationActivity />} />
              )}
              <Route
                path=":appSlug/*"
                element={<OrganizationAppIdResolver />}
              />
              <Route path="" element={<Navigate to="settings" replace />} />
            </Route>
            <Route path="/login" element={<Navigate to="/" replace />} />

            <Route path="/" element={<HomeScene />} />

            <Route path="/*" element={<UserManagementLayout />}>
              <Route path="" element={<Navigate to="apps" replace />} />
              <Route path="apps/*" element={<UserAppsRouter />} />
              <Route path="new-app" element={<CreateUserAppScene />} />
              <Route
                path="organizations"
                element={<UserOrganizationsScene />}
              />
              {isSuperAdmin && (
                <Route
                  path="employee-groups"
                  element={<UserEmployeeGroupsScene />}
                />
              )}
              <Route path="settings" element={<UserSettingsScene />} />
              <Route path="notifications" element={<NotificationsScene />} />
            </Route>

            <Route path="/app/:appSlug/*" element={<UserAppIdResolver />} />
            <Route
              path="/u/:userId/:appSlug/*"
              element={<UserAppIdResolver />}
            />

            <Route path="/u/:userId/*" element={<UserManagementLayout />}>
              <Route path="" element={<Navigate to="apps" replace />} />
              <Route path="apps/*" element={<UserAppsRouter />} />
              <Route path="new-app" element={<CreateUserAppScene />} />
              <Route
                path="organizations"
                element={<UserOrganizationsScene />}
              />
              {isSuperAdmin && (
                <Route
                  path="employee-groups"
                  element={<UserEmployeeGroupsScene />}
                />
              )}
              <Route path="new-app" element={<CreateUserAppScene />} />
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </MainLayout>
  )
}

export default AppRouter
