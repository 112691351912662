import graphql__7a68be31dd3a2ad06627 from "./__generated__/PersonalAppsMenuItemQuery.graphql.ts";
import graphql__07ef98483e8311580158 from "./__generated__/PersonalAppsMenuItem_viewer.graphql.ts";import { Menu } from '@headlessui/react'
import { RawLink } from '@portal/components'
import graphql from 'babel-plugin-relay/macro'
import classNames from 'classnames'
import paths from 'paths'
import { useEffect } from 'react'
import { useLazyLoadQuery, useRefetchableFragment } from 'react-relay/hooks'

import { PersonalAppsMenuItem_viewer$key } from './__generated__/PersonalAppsMenuItem_viewer.graphql'
import { PersonalAppsMenuItemQuery } from './__generated__/PersonalAppsMenuItemQuery.graphql'
import { ViewerAppsRefetchQuery } from './__generated__/ViewerAppsRefetchQuery.graphql'

const query = graphql__7a68be31dd3a2ad06627

const refetchableApps = graphql__07ef98483e8311580158

type Props = {
  /** True if the menu item is open */
  isOpen: boolean
}

// Menu item for accessing the user's personal apps.
export default function PersonalAppsMenuItem({ isOpen }: Props) {
  const { viewer } = useLazyLoadQuery<PersonalAppsMenuItemQuery>(query, {})
  const [data, refetch] = useRefetchableFragment<
    ViewerAppsRefetchQuery,
    PersonalAppsMenuItem_viewer$key
  >(refetchableApps, viewer)

  useEffect(() => {
    if (isOpen) {
      refetch({}, { fetchPolicy: 'store-and-network' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <div className="pb-2">
      {data.me?.apps?.map((userApp) => (
        <Menu.Item key={userApp.id}>
          {({ active }) => (
            <RawLink
              to={paths.app({
                appSlug: userApp.slug,
              })}
              className={classNames(
                'group flex h-12 w-full items-center justify-between px-4 text-base text-foreground-normal hover:bg-background-overlay-hovered',
                { 'bg-background-overlay': !active },
                { 'bg-background-overlay-hovered': active },
              )}
            >
              <span className="truncate whitespace-nowrap">{userApp.name}</span>
            </RawLink>
          )}
        </Menu.Item>
      ))}
      {!data.me?.apps?.length && (
        <span className="flex h-12 items-center bg-background-overlay px-4 text-base text-foreground-subtle">
          You do not have any apps yet
        </span>
      )}
    </div>
  )
}
