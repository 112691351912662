import graphql__39baa8af55cebd42e038 from "./__generated__/AppHeader_app.graphql.ts";import { icons, Link, useCopyToClipboard } from '@portal/components'
import * as Sentry from '@sentry/browser'
import graphql from 'babel-plugin-relay/macro'
import cx from 'classnames'
import useAppAuthentication from 'components/authenticationWebhooks'
import { getPlaygroundProvider } from 'components/authenticationWebhooks/useAppAuthentication'
import paths from 'paths'
import { useContext, useEffect } from 'react'
import { useFragment } from 'react-relay/hooks'
import { useParams } from 'react-router-dom'
import { useFeatureFlags } from 'utils/featureFlags'

import { AppHeader_app$key } from './__generated__/AppHeader_app.graphql'
import AppForgeLink from './AppForgeLink'
import AppSettingsContext from './context'
import IngressDomainLoadingStatus from './IngressDomainLoadingStatus'

const fragment = graphql__39baa8af55cebd42e038

const { Copy, Check } = icons

type Props = {
  /** App header key. */
  app: AppHeader_app$key | null
}

/** Renders a header for the organization pages */
const AppHeader = ({ app }: Props) => {
  const data = useFragment(fragment, app)
  const params = useParams<{ userId?: string }>()
  const { isDomainReady } = useContext(AppSettingsContext)
  const { configuration, isAuthEnabled } = useAppAuthentication()
  const playgroundToken: string =
    getPlaygroundProvider(configuration)?.sharedToken ?? ''
  const { appForge } = useFeatureFlags()

  const [doCopyAppId, appIdRecentlyCopied] = useCopyToClipboard(data?.id ?? '')
  const [doCopyPlaygroundToken, playgroundTokenRecentlyCopied] =
    useCopyToClipboard(playgroundToken)

  const getGroupLink = () => {
    if (data?.tenant.__typename === 'Organization') {
      return paths.organizationManagement({ slug: data.tenant.slug })
    }
    if (data?.tenant.__typename === 'User') {
      return !!params.userId
        ? paths.userApps({
            userId: params.userId,
          })
        : paths.gettingStarted()
    }

    return ''
  }

  const userName = () => {
    if (data?.tenant.__typename === 'User') {
      return data.tenant.firstName || data.tenant.lastName
        ? `${data.tenant.firstName} ${data.tenant.lastName}`
        : data.tenant.email
    }

    return ''
  }

  const truncateString = (str: string) => {
    const start = str.slice(0, 5)
    const end = str.slice(-5)
    return `${start}...${end}`
  }

  /** Report missing fragment key or data to help debug SURF-292. */
  useEffect(() => {
    if (!app || !data) {
      Sentry.captureMessage(`Failed to load AppHeader_app fragment`, {
        level: 'warning',
        tags: {
          appProp: JSON.stringify(app),
          fragmentData: JSON.stringify(data),
        },
      })
    }
  }, [app, data])

  return (
    <div className="bg-background">
      <div className="mx-auto max-w-7xl px-4">
        <div className="flex flex-col justify-between pb-6 pt-4 lg:flex-row">
          <div className="mb-4 px-4 lg:mb-0 lg:px-0">
            <Link
              data-testid="groupLink"
              to={getGroupLink()}
              className="text-foreground-accent mb-1 inline-block text-sm outline-none hover:opacity-80 focus-visible:outline-2 focus-visible:outline-focus-outline"
            >
              {data?.tenant.__typename === 'Organization' &&
                `${data.tenant.name} /`}
              {data?.tenant.__typename === 'User' && `${userName()} /`}
            </Link>
            <h1 className="font-aeonik text-2xl font-medium text-foreground-normal sm:text-3xl">
              {data?.name}
            </h1>
          </div>
          {isDomainReady && (
            <div
              className="flex overflow-x-auto px-4 scrollbar-hide lg:overflow-x-visible lg:px-0 items-end space-x-8"
              data-testid="appMetaData"
            >
              {configuration && (
                <div data-testid="appAuthMode">
                  <span className="mb-2 block whitespace-nowrap text-sm text-foreground-subtle">
                    Auth mode
                  </span>
                  <span
                    className={cx(
                      'block whitespace-nowrap border-l-2 pl-1.5 text-sm text-foreground-normal',
                      {
                        'border-green-500': isAuthEnabled,
                        'border-amber-500': !isAuthEnabled,
                      },
                    )}
                  >
                    {isAuthEnabled ? 'Authentication enabled' : 'Playground'}
                  </span>
                </div>
              )}
              <div>
                <span className="mb-2 block whitespace-nowrap text-sm text-foreground-subtle">
                  App ID
                </span>
                <span className="flex items-center whitespace-nowrap text-sm text-foreground-normal">
                  <span title={data?.id} className="font-plex-mono">
                    {truncateString(data?.id || '')}
                  </span>
                  <button
                    onClick={doCopyAppId}
                    className="group ml-1 outline-none focus-visible:outline-2 focus-visible:outline-focus-outline"
                  >
                    {appIdRecentlyCopied ? (
                      <Check className="h-4 w-4 text-fill-success" />
                    ) : (
                      <Copy className="h-4 w-4 text-foreground-subtle hover:text-foreground-normal group-focus-visible:text-foreground-normal" />
                    )}
                  </button>
                </span>
              </div>
              {playgroundToken && (
                <div data-testid="appPlaygroundToken">
                  <span className="mb-2 block whitespace-nowrap text-sm text-foreground-subtle">
                    Playground token
                  </span>
                  <span className="flex items-center whitespace-nowrap text-sm text-foreground-normal">
                    <span title={playgroundToken} className="font-plex-mono">
                      {truncateString(playgroundToken)}
                    </span>
                    <button
                      onClick={doCopyPlaygroundToken}
                      className="group ml-1 outline-none focus-visible:outline-2 focus-visible:outline-focus-outline"
                    >
                      {playgroundTokenRecentlyCopied ? (
                        <Check className="h-4 w-4 text-fill-success" />
                      ) : (
                        <Copy className="h-4 w-4 text-foreground-subtle hover:text-foreground-normal group-focus-visible:text-foreground-normal" />
                      )}
                    </button>
                  </span>
                </div>
              )}

              {appForge && data?.id && <AppForgeLink appId={data.id} />}
            </div>
          )}
        </div>
        <div className="px-4 lg:mb-0 lg:px-0">
          <IngressDomainLoadingStatus />
        </div>
      </div>
    </div>
  )
}

export default AppHeader
