import { Suspense } from 'react'

import AppManagementScene from './AppManagementScene'
import useActiveDomain from './hooks/useActiveDomain'

type Props = {
  //Current app ID
  appId: string
}

/**
 * Component used to ensure that the Ditto app has been correctly initialized. Will show a loader
 * component until the app is ready, explaining that the app is currently being configured.
 */
const AppManagementLoader = ({ appId }: Props) => {
  const { error, isDomainInitiallyReady, domain, queryAttempts } =
    useActiveDomain(appId)

  return (
    <Suspense fallback={null}>
      <AppManagementScene
        appId={appId}
        isDomainInitiallyReady={isDomainInitiallyReady}
        isDomainReady={!!domain}
        isDomainInError={error}
        queryAttempts={queryAttempts}
      />
    </Suspense>
  )
}

export default AppManagementLoader
