import { TabItem } from 'components/tabs'
import TabsWrapper from 'components/tabs/TabsWrapper'
import paths from 'paths'
import { useMemo } from 'react'

import { useEmployeeGroupContext } from './context'

export default function EmployeeGroupNavigation() {
  const { slug } = useEmployeeGroupContext()

  const tabItems: TabItem[] = useMemo(
    () => [
      {
        key: 'customers',
        text: 'Customers',
        to: paths.employeeGroupManagement({
          slug,
          area: 'customers',
        }),
      },
      {
        key: 'employees',
        text: 'Employees',
        to: paths.employeeGroupManagement({
          slug,
          area: 'employees',
        }),
      },
      {
        key: 'settings',
        text: 'Settings',
        to: paths.employeeGroupManagement({
          slug,
          area: 'settings',
        }),
      },
    ],
    [slug],
  )

  return <TabsWrapper items={tabItems} />
}
