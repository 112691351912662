import { Menu } from '@headlessui/react'
import { icons, RawLink } from '@portal/components'
import classNames from 'classnames'
import paths from 'paths'

const { ArrowRight } = icons

export default function MoreOrganizationsMenuItem() {
  return (
    <Menu.Item>
      {({ active }) => (
        <RawLink
          to={paths.userOrganizations({})}
          className={classNames(
            'group flex h-12 w-full items-center justify-between px-4 text-base text-foreground-normal hover:bg-background-overlay-hovered',
            { 'bg-background-overlay': !active },
            { 'bg-background-overlay-hovered': active },
          )}
        >
          <span className="text-foreground-normal">
            More organizations & apps
          </span>
          <ArrowRight
            className={classNames(
              'h-4 w-4 group-hover:text-foreground-normal',
              { 'text-foreground-normal': active },
              { 'text-foreground-subtle': !active },
            )}
          />
        </RawLink>
      )}
    </Menu.Item>
  )
}
