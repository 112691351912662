import React from 'react'

import { useDittoAppConnection } from '../../components/dittoAppConnection'
import AppSettingsContext from './context'

type Props = {
  /** Current App ID */
  appId: string
  /** Child elements */
  children: React.ReactNode
  /** True if the domain status is ready since the first moment the status is queried. */
  isDomainInitiallyReady: boolean
  /** True if the app is on a dedicated cluster. */
  isDedicatedCluster: boolean
  /** True if the ingress domain for the app is ready. */
  isDomainReady: boolean
  /** True if the ingress domain for the app is in error state. */
  isDomainInError: boolean
  /** Number of times the domain status has been queried. */
  queryAttempts: number
  // TODO(2367): Remove 'webhooks'
  /** True if the appWebhooks flag is set OR the app has an existing webhook configuration OR isDedicatedCluster is true */
  allowAppWebhooks: boolean
  /** True if the streams feature is available to the user */
  allowStreams: boolean
  /** True if the viewer can update the app */
  viewerCanUpdateApp: boolean
  /** True if the viewer can access metrics */
  viewerCanAccessMetrics: boolean
  /** True if the viewer can read app data */
  viewerCanReadAppData: boolean
  /** True if the viewer can read offline licenses */
  viewerCanReadLicense: boolean
  /** True if the viewer can read API keys */
  viewerCanReadApiKey: boolean
}

/** Context provider for the app management area. */
const AppManagementContextProvider = ({
  appId,
  children,
  isDedicatedCluster,
  isDomainInitiallyReady,
  isDomainReady,
  isDomainInError,
  queryAttempts,
  allowAppWebhooks,
  allowStreams,
  viewerCanUpdateApp,
  viewerCanAccessMetrics,
  viewerCanReadAppData,
  viewerCanReadApiKey,
  viewerCanReadLicense,
}: Props) => {
  const { error, cloudDomain } = useDittoAppConnection(appId, isDomainReady)

  return (
    <AppSettingsContext.Provider
      value={{
        error,
        appId,
        isDomainInitiallyReady,
        isDedicatedCluster,
        isDomainReady,
        isDomainInError,
        queryAttempts,
        allowAppWebhooks,
        allowStreams,
        viewerCanAccessMetrics,
        viewerCanReadAppData,
        cloudDomainUrl: cloudDomain?.url,
        viewerCanReadLicense,
        viewerCanReadApiKey,
        viewerCanUpdateApp,
      }}
    >
      {children}
    </AppSettingsContext.Provider>
  )
}

export default AppManagementContextProvider
