import { useDitto } from '@dittolive/react-ditto'
import { useEffect } from 'react'
import { useFeatureFlags } from 'utils/featureFlags'

import { SystemCollections, SystemIds } from '../components/dittoAppConnection'

/** Initializes the default Kafka configuration if no configuration is available. */
const useInitializeKafkaConfiguration = (
  appId: string,
  isDedicatedCluster: boolean,
  isDomainReady: boolean,
) => {
  const { ditto, loading, error } = useDitto(isDomainReady ? appId : undefined)
  const { appStreams } = useFeatureFlags()

  useEffect(() => {
    // When we enable app streams, we don't need to initialize the *legacy* Kafka configuration
    if (appStreams) {
      return
    }

    const ensureDefaultKafkaConfiguration = async () => {
      if (ditto) {
        const featuresCollection = ditto.store.collection(
          SystemCollections.APP_HYDRA_FEATURES_COLLECTION,
        )
        const document = await featuresCollection
          .findByID(SystemIds.HYDRA_KAFKA_FEATURES)
          .exec()

        if (!document) {
          await featuresCollection.upsert(
            {
              _id: SystemIds.HYDRA_KAFKA_FEATURES,
              enabled: isDedicatedCluster,
            },
            { writeStrategy: 'insertDefaultIfAbsent' },
          )
        }
      }
    }

    if (ditto && !loading && !error) {
      ensureDefaultKafkaConfiguration()
    }
  }, [ditto, loading, error, isDedicatedCluster, appStreams])
}

export default useInitializeKafkaConfiguration
