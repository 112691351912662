import graphql__7e40513d6e984218645f from "./__generated__/UserManagementHeader_userInfo.graphql.ts";import graphql from 'babel-plugin-relay/macro'
import { Container } from 'components/layout'
import { useFragment } from 'react-relay/hooks'

import { UserManagementHeader_userInfo$key } from './__generated__/UserManagementHeader_userInfo.graphql'

const userInfoFragment = graphql__7e40513d6e984218645f

type Props = {
  user: UserManagementHeader_userInfo$key
}

export default function UserManagementHeader({ user }: Props) {
  const data = useFragment(userInfoFragment, user)

  return (
    <div className="h-24 bg-background">
      <Container className="flex h-full flex-col justify-center py-4">
        <h1
          className="font-aeonik text-3xl font-medium"
          data-testid="userTenantHeader"
        >
          {data.firstName} {data.lastName}
        </h1>
      </Container>
    </div>
  )
}
