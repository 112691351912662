import graphql__84c5554fd94e758223f6 from "./__generated__/AppTenantMenuQuery.graphql.ts";import { Menu } from '@headlessui/react'
import { icons } from '@portal/components'
import graphql from 'babel-plugin-relay/macro'
import classNames from 'classnames'
import { TenantContext } from 'components/tenant'
import { isStaticPath } from 'paths'
import { Suspense, useContext, useEffect, useState } from 'react'
import { useLazyLoadQuery } from 'react-relay/hooks'
import { useLocation, useNavigate } from 'react-router-dom'

import { AppTenantMenuQuery } from './__generated__/AppTenantMenuQuery.graphql'
import CreateOrganizationMenuItem from './CreateOrganizationMenuItem'
import OrganizationAppsMenuItem from './OrganizationAppsMenuItem'
import OrganizationsMenuItem from './OrganizationsMenuItem'
import PersonalAppsMenuItem from './PersonalAppsMenuItem'
import RecentOrganizationAppsMenuItem from './RecentOrganizationAppsMenuItem'

const appTenantQuery = graphql__84c5554fd94e758223f6

const { ArrowCircle, ChevronRight, ChevronUpDown } = icons

/** Menu used to manage app tenants and to navigate between tenants. */
export default function AppTenantMenu() {
  const { tenant, updateTenant, getTenantHome } = useContext(TenantContext)
  const skipOrganization = !tenant
  const { viewer } = useLazyLoadQuery<AppTenantMenuQuery>(appTenantQuery, {
    slug: tenant || '',
    skipOrganization,
  })

  const location = useLocation()
  const navigate = useNavigate()

  const [activeView, setActiveView] = useState<'main' | 'tenants'>('main')
  const activeTenant = viewer.organization?.name || 'Personal'

  useEffect(() => {
    setActiveView('main')
  }, [tenant])

  const renderApps = (isOpen: boolean) => {
    if (tenant) {
      return (
        <Suspense fallback={null}>
          <OrganizationAppsMenuItem isOpen={isOpen} slug={tenant} />
        </Suspense>
      )
    }

    return (
      <Suspense fallback={null}>
        <PersonalAppsMenuItem isOpen={isOpen} />
      </Suspense>
    )
  }

  const renderMainMenu = (isOpen: boolean) => {
    if (activeView !== 'main') {
      return null
    }

    return (
      <>
        <span className="block px-4 pb-1 pt-2 text-sm text-foreground-subtle">
          Apps
        </span>

        {renderApps(isOpen)}
        <Suspense fallback={null}>
          <RecentOrganizationAppsMenuItem isOpen={isOpen} />
        </Suspense>

        <div className="border-border-normal border-t pt-2">
          <Menu.Item>
            {({ active }) => (
              <button
                data-testid="mainViewSwitchTenantButton"
                onClick={(e) => {
                  e.preventDefault()
                  setActiveView('tenants')
                }}
                className={classNames(
                  'group flex h-12 w-full items-center justify-between px-4 text-base text-foreground-normal hover:bg-background-overlay-hovered',
                  { 'bg-background-overlay': !active },
                  { 'bg-background-overlay-hovered': active },
                )}
              >
                <span className="flex items-center">
                  <ArrowCircle
                    className={classNames(
                      'mr-2 h-4 w-4 group-hover:text-foreground-normal',
                      {
                        'text-foreground-normal': active,
                        'text-foreground-subtle': !active,
                      },
                    )}
                  />
                  <span>Switch Organization</span>
                </span>
                <ChevronRight
                  className={classNames(
                    'h-4 w-4 group-hover:text-foreground-normal',
                    {
                      'text-foreground-normal': active,
                      'text-foreground-subtle': !active,
                    },
                  )}
                />
              </button>
            )}
          </Menu.Item>
          <CreateOrganizationMenuItem />
        </div>
      </>
    )
  }

  const handleSwitchTenant = (tenant: string | null) => {
    const redirectPath = getTenantHome(tenant)

    // NOTE: this step is important, otherwise if we route to /, we will be redirected back
    // here since the tenant wasn't updated.
    updateTenant(tenant)

    const pathname = location.pathname
    const shouldRedirect = !isStaticPath(pathname)
    if (shouldRedirect) {
      navigate(redirectPath)
    }
  }

  return (
    <Menu as="div" className="min-w-0 flex-initial sm:relative">
      {({ open }) => (
        <>
          <Menu.Button
            data-testid="appTenantMenuTrigger"
            className="group flex h-12 max-w-full flex-initial appearance-none items-center px-2 text-base outline-none focus-visible:outline-2 focus-visible:outline-offset-[-2px] focus-visible:outline-focus-outline sm:px-4"
          >
            <span className="mr-2 truncate whitespace-nowrap text-foreground-normal">
              {activeTenant}
            </span>
            <ChevronUpDown className="h-4 w-4 flex-none text-foreground-subtle group-hover:text-foreground-normal group-focus-visible:text-foreground-normal" />
          </Menu.Button>
          <Menu.Items className="absolute left-0 z-50 max-h-[calc(100vh-96px)] w-full overflow-y-auto bg-background-overlay py-2 shadow-lg outline-none sm:max-h-[calc(100vh-72px)] sm:w-[320px]">
            {/* NOTE: I render both 'pages' of this menu so the tenants view query gets run when it first opens.
                  Otherwise, there is a little lag while the query runs that looks terrible. */}
            {renderMainMenu(open)}
            <Suspense fallback={null}>
              <OrganizationsMenuItem
                isOpen={open && activeView === 'tenants'}
                onClose={() => setActiveView('main')}
                onChangeTenant={handleSwitchTenant}
              />
            </Suspense>
          </Menu.Items>
        </>
      )}
    </Menu>
  )
}
