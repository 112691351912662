import graphql__8ea4c84a4c9c35388803 from "./__generated__/AppManagementSceneQuery.graphql.ts";
import graphql__751ac0e311fc61384bfd from "./__generated__/AppManagementSceneVisitMutation.graphql.ts";import { usePendingCursorOperation } from '@dittolive/react-ditto'
import graphql from 'babel-plugin-relay/macro'
import { AnnouncementsContainer } from 'components/announcement'
import { SystemCollections } from 'components/dittoAppConnection'
import { usePrefetchQuery } from 'components/relay'
import TabsWrapper from 'components/tabs'
import { useApplyTenant } from 'components/tenant'
import AppContext from 'context'
import paths from 'paths'
import { Suspense, useContext, useEffect, useMemo } from 'react'
import {
  PreloadedQuery,
  useMutation,
  usePreloadedQuery,
  useQueryLoader,
} from 'react-relay/hooks'
import { useParams } from 'react-router-dom'
import { useFeatureFlags } from 'utils/featureFlags'

import useInitializeKafkaConfiguration from '../../dittoPortalProvider/useInitializeKafkaConfiguration'
import { AppManagementSceneQuery } from './__generated__/AppManagementSceneQuery.graphql'
import { AppManagementSceneVisitMutation } from './__generated__/AppManagementSceneVisitMutation.graphql'
import AppHeader from './AppHeader'
import AppManagementContextProvider from './AppManagementContextProvider'
import AppManagementRouter from './AppManagementRouter'
import {
  AppAuthAPIConnectionSectionQuery,
  AppAuthRouterQuery,
} from './tabs/auth'
import {
  AppDangerSectionQuery,
  AppSettingsFormQuery,
  TransferAppSectionQuery,
} from './tabs/settings'

export const query = graphql__8ea4c84a4c9c35388803

const visitMutation = graphql__751ac0e311fc61384bfd

type ContainerProps = {
  /** Current app ID. */
  appId: string
  /** True if the domain status is ready since the first moment the status is queried. */
  isDomainInitiallyReady: boolean
  /** True if the ingress domain for the app is ready. */
  isDomainReady: boolean
  /** True if the ingress domain for the app is in error state. */
  isDomainInError: boolean
  /** Number of times the domain status has been queried. */
  queryAttempts: number
}

/** Scene for managing apps */
export default function AppManagementSceneContainer(props: ContainerProps) {
  const [queryRef, loadQuery] = useQueryLoader<AppManagementSceneQuery>(query)

  const { appId } = props
  useEffect(() => {
    loadQuery({ appId }, { fetchPolicy: 'store-and-network' })
  }, [appId, loadQuery])

  if (!queryRef) {
    return null
  }

  return <AppManagementScene {...props} queryRef={queryRef} />
}

type Props = {
  queryRef: PreloadedQuery<AppManagementSceneQuery>
} & ContainerProps

function AppManagementScene({
  queryRef,
  appId,
  isDomainInitiallyReady,
  isDomainReady,
  isDomainInError,
  queryAttempts,
}: Props) {
  const params = useParams<{
    organizationSlug: string
    appSlug: string
    userId: string
  }>()
  const { isSuperAdmin } = useContext(AppContext)
  const { viewer } = usePreloadedQuery(query, queryRef)
  const { appWebhooks, appStreams, deviceDashboard, metrics } =
    useFeatureFlags()
  // TODO(2367): Once streams is fully released, remove this
  const { documents: webhooks } = usePendingCursorOperation({
    path: appId,
    collection: SystemCollections.APP_LIVE_QUERY_WEBHOOKS_COLLECTION,
  })
  const { documents: sinks } = usePendingCursorOperation({
    path: appId,
    collection: SystemCollections.LIVE_QUERY_SINKS_COLLECTION,
    limit: 1,
  })

  const viewerCanUpdateApp = viewer.appById?.viewerCanUpdate ?? false
  const viewerCanReadAppData = viewer.appById?.viewerCanReadAppData ?? false
  const viewerCanReadStreams = viewer.appById?.viewerCanReadStreams ?? false
  const viewerCanReadLicense = viewer.appById?.viewerCanReadLicense ?? false
  const viewerCanReadApiKey = viewer.appById?.viewerCanReadApiKey ?? false
  const isDedicatedCluster = !!viewer.appById?.hydraCluster?.isDedicated

  // TODO(2367): Once streams is fully released, remove this
  useInitializeKafkaConfiguration(appId, isDedicatedCluster, isDomainReady)

  // TODO(2367): Validate this is the desired behavior re: grandfathering in non-dedicated clusters
  // if they had a webhook configuration which was converted to a stream.
  const hasConfiguredStream = !!sinks.length
  const streamsEnabled =
    !!appStreams && (isDedicatedCluster || hasConfiguredStream)
  const allowStreams = streamsEnabled && viewerCanReadStreams

  // TODO(2367): Validate this is the desired behavior. Once we turn on streams,
  // we DO NOT want users to be able to access webhooks.
  const allowAppWebhooks = !!appStreams
    ? !!isSuperAdmin
    : isSuperAdmin || isDedicatedCluster || appWebhooks || webhooks.length > 0

  const allowMetrics =
    !!(isSuperAdmin || viewer.appById?.viewerCanAccessMetrics) && !!metrics
  useApplyTenant(params.organizationSlug!)
  const { prefetchQueries } = usePrefetchQuery()

  const [markAppAsVisited] =
    useMutation<AppManagementSceneVisitMutation>(visitMutation)

  useEffect(() => {
    if (viewer.appById) {
      markAppAsVisited({
        variables: { appId },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewer.appById])

  const showCollections = viewerCanReadAppData || !!isSuperAdmin
  const showDevices = deviceDashboard && viewerCanReadAppData

  const atLeastOneAuthSection =
    viewerCanReadLicense || viewerCanReadApiKey || viewerCanReadAppData
  const showAuth = atLeastOneAuthSection || !!isSuperAdmin

  const tabItems = useMemo(
    () => [
      ...(showAuth
        ? [
            {
              key: 'connect',
              text: 'Connect',
              to: paths.appManagement({
                ...params,
                appSlug: params.appSlug || '',
                tenantId: params.userId,
                area: 'connect',
              }),
              onMouseEnter: () =>
                prefetchQueries(
                  [AppAuthAPIConnectionSectionQuery, AppAuthRouterQuery],
                  { appId },
                ),
            },
          ]
        : []),
      ...(isDomainReady && showCollections
        ? [
            {
              key: 'collections',
              text: 'Collections',
              to: paths.appManagement({
                ...params,
                appSlug: params.appSlug || '',
                tenantId: params.userId,
                area: 'collections',
              }),
            },
          ]
        : []),
      ...(isDomainReady && showDevices
        ? [
            {
              key: 'devices',
              text: 'Devices',
              to: paths.appManagement({
                ...params,
                appSlug: params.appSlug || '',
                tenantId: params.userId,
                area: 'devices',
              }),
            },
          ]
        : []),
      ...(allowMetrics
        ? [
            {
              key: 'metrics',
              text: 'Metrics',
              to: paths.appManagement({
                ...params,
                appSlug: params.appSlug || '',
                tenantId: params.userId,
                area: 'metrics',
              }),
            },
          ]
        : []),
      ...(process.env.NODE_ENV !== 'production'
        ? [
            {
              key: 'issues',
              text: 'Issues',
              to: paths.appManagement({
                ...params,
                appSlug: params.appSlug || '',
                tenantId: params.userId,
                area: 'issues',
              }),
            },
          ]
        : []),
      // TODO(2367): Remove 'webhooks' from AppManagementArea
      // NOTE: this feature flag check was added as part of https://github.com/getditto/cloud-services/issues/1984
      ...(isDomainReady && allowAppWebhooks
        ? [
            {
              key: 'webhooks',
              text: 'Live Query Settings',
              to: paths.appManagement({
                ...params,
                appSlug: params.appSlug || '',
                tenantId: params.userId,
                area: 'webhooks',
              }),
            },
          ]
        : []),
      ...(isDomainReady && allowStreams
        ? [
            {
              key: 'cdc',
              text: 'Change Data Capture',
              to: paths.appManagement({
                ...params,
                appSlug: params.appSlug || '',
                tenantId: params.userId,
                area: 'cdc',
              }),
            },
          ]
        : []),
      {
        key: 'settings',
        text: 'Settings',
        to: paths.appManagement({
          ...params,
          appSlug: params.appSlug || '',
          tenantId: params.userId,
          area: 'settings',
        }),
        onMouseEnter: () =>
          prefetchQueries(
            [
              AppSettingsFormQuery,
              AppDangerSectionQuery,
              TransferAppSectionQuery,
            ],
            {
              appId,
            },
          ),
      },
    ],
    [
      params,
      isDomainReady,
      allowAppWebhooks,
      showDevices,
      allowMetrics,
      prefetchQueries,
      appId,
      showCollections,
      allowStreams,
      showAuth,
    ],
  )

  const renderAppContents = () => (
    <>
      <AnnouncementsContainer announcements={viewer.appById?.announcements} />
      <Suspense fallback={null}>
        <AppHeader app={viewer.appById} />
      </Suspense>
      {tabItems.length > 1 && <TabsWrapper items={tabItems} />}
      <Suspense fallback={null}>
        <AppManagementRouter appId={appId} />
      </Suspense>
    </>
  )

  return (
    <AppManagementContextProvider
      appId={appId}
      isDomainInitiallyReady={isDomainInitiallyReady}
      isDomainReady={isDomainReady}
      isDomainInError={isDomainInError}
      queryAttempts={queryAttempts}
      isDedicatedCluster={isDedicatedCluster}
      allowAppWebhooks={allowAppWebhooks}
      allowStreams={allowStreams}
      viewerCanAccessMetrics={allowMetrics}
      viewerCanReadAppData={viewerCanReadAppData}
      viewerCanReadLicense={viewerCanReadLicense}
      viewerCanReadApiKey={viewerCanReadApiKey}
      viewerCanUpdateApp={viewerCanUpdateApp}
    >
      {renderAppContents()}
    </AppManagementContextProvider>
  )
}
