import { DittoLogo, Heading } from '@portal/components'
import * as Sentry from '@sentry/browser'
import { useEffect } from 'react'

/** Scene shown to users that load the portal with an unsupported browser. */
export default function UnsupportedBrowserScene() {
  useEffect(() => {
    Sentry.withScope((scope) => {
      scope.setTag('unsupported-browser', navigator.userAgent)
      Sentry.captureMessage('Unsupported browser')
    })
  }, [])

  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center space-y-4 p-8">
      <DittoLogo className="block h-20 w-auto" />

      <Heading level={2}>Sorry! We don&apos;t support your browser.</Heading>
      <p className="max-w-lg dark:text-gray-400">
        Please switch over to another browser or update your browser in order to
        access the portal.
      </p>
    </div>
  )
}
