import { usePendingCursorOperation } from '@dittolive/react-ditto'
import { icons } from '@portal/components'
import { SystemCollections } from 'components/dittoAppConnection'
import paths from 'paths'
import { Link } from 'react-router-dom'

const { ArrowUpRight } = icons

type Props = {
  appId: string
}

export default function AppForgeLink({ appId }: Props) {
  const { documents } = usePendingCursorOperation({
    path: appId,
    collection: SystemCollections.FORGE_PROJECTS_COLLECTION,
    limit: 1,
  })

  const url = paths.forge({ appId, projectId: documents[0]?.id.toString() })

  return (
    <Link
      to={url}
      className="inline-flex items-center space-x-2 bg-background-surface-hovered shrink-0 rounded-full py-1 pl-1 pr-3"
      data-testid="forgeLink"
    >
      <span className="bg-[#A32100] text-white px-2 py-1 text-sm items-center rounded-full justify-center">
        Alpha
      </span>

      <div className="flex items-center space-x-1">
        <span>App builder</span>
        <ArrowUpRight className="h-4 w-4 text-foreground-subtle" />
      </div>
    </Link>
  )
}
