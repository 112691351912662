import './index.css'
import 'core-js'
import '@portal/components/font/inter/inter.css'
import '@portal/components/font/ibmPlexMono/ibmPlexMono.css'
import '@portal/components/font/inconsolata/inconsolata.css'
import '@portal/components/font/aeonikPro/aeonik.css'
import 'overlayscrollbars/overlayscrollbars.css'

import * as Sentry from '@sentry/browser'
import App from 'App'
import React from 'react'
import { createRoot } from 'react-dom/client'
import TagManager from 'react-gtm-module'

const windowEnvironment = window._env_

if (windowEnvironment.APP_SENTRY_DSN_URL) {
  Sentry.init({
    dsn: windowEnvironment.APP_SENTRY_DSN_URL,
    transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.captureConsoleIntegration({
        levels: ['error'],
      }),
      Sentry.dedupeIntegration(),
      Sentry.reportingObserverIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: windowEnvironment.APP_ENV,
    // Before an event is emitted, attach a breadcrumb that contains
    // the version of the portal.
    beforeSend(event) {
      const defaultBreadcrum: Sentry.Breadcrumb = {
        category: 'versions',
        level: 'debug',
        data: {
          PORTAL_FRONTEND_BUILD_VERSION: windowEnvironment.BUILD_VERSION,
        },
      }
      if (event.breadcrumbs) {
        event.breadcrumbs.push(defaultBreadcrum)
      } else {
        event.breadcrumbs = [defaultBreadcrum]
      }

      return event
    },
  })
}

if (
  windowEnvironment.GOOGLE_TAG_MANAGER_KEY &&
  windowEnvironment.GOOGLE_TAG_MANAGER_ENV_AUTH &&
  windowEnvironment.GOOGLE_TAG_MANAGER_PREVIEW_ENV_ID
) {
  TagManager.initialize({
    gtmId: windowEnvironment.GOOGLE_TAG_MANAGER_KEY,
    auth: windowEnvironment.GOOGLE_TAG_MANAGER_ENV_AUTH,
    preview: windowEnvironment.GOOGLE_TAG_MANAGER_PREVIEW_ENV_ID,
  })
}

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
